import React from 'react';

export const sw = {
  title: 'Sauti ya Matengenezo',
  home: 'Utangurizi',
  logoTitle: (
    <>
      <span>Sauti ya </span>
      <strong>Matengenezo</strong>
    </>
  ),
  listenRadio: 'Sikiliza radio',
  contactUs: 'Tuandikie',
  radioName: 'Radio Sauti ya Matengenezo',
  listen: 'Bonyeza hapa usikilize',
  radioMsg:
    'Nyimbo za Radio katika lengo la vipengere tofauti, uzima, familia, unabii nazingine nzuri zamaana',
  audioVideoTxt: 'Sauti pamoja na video',
  recentTopics: 'Nakala za hivi karibuni',
  relatedTopics: 'Nakala zinazo husiana',
  writingsCat: 'Kichwa cha habari',
  leaveComment: 'Andika maoni yako juu ya makala hii',
  notEmailPublish: 'Email yako haita tangazwa',
  createdAt: 'ilitengenezwa ',
  commentSuccess: 'Wazolako limepokelewa. Asante',
  mostReadTopics: 'Makala zilizo somwa zaidi',
  btnSend: 'Tuma',
};
