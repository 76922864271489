import { en } from './en';
import { fr } from './fr';
import { kn } from './kn';
import { sw } from './sw';

export const messages = {
  en,
  kn,
  fr,
  sw,
};
